import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "In-Spite Of",
  "author": "Christina",
  "date": "2020-02-17T08:45:37.000Z",
  "categories": ["Black History Month", "Diversity and Inclusion"],
  "slug": "in-spite-of",
  "draft": false,
  "meta_title": "In-Spite Of",
  "cover": "../../images/wp_blog_images/In-spite-Of.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I have always loved history!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "940px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "83.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAD00lEQVQ4y12Ty09bVxDG7zYEvx/Xj+vXtbF9/cBc29cvbAPG2Bgw2IbEEKetaRJIQqCugHaBRKWoqy6q/gNddFEpUtVV1b/vF/lESZMuRmfOnDPf+c7MfJKmadhsNsxmM1arTfh2u515PJ/PE4tGxZnFYhFmNls++f+3+T1JVVWsVitutxuf14vP58Xlcon9ZmuDWrWMxWzCZrXisNuwWi2YTCYWF01fPPQJUFH8YuP1epFlGa/Ph8//IbbVbtFsNnj48KF41Om043Y5CYVC5HLLhMNhAf45qORyOanVqmx3t+hstTCKOplUAn05xdeTxwz6OwT8MvFoEDXsJxlX2e1tMf3qmN3tNqFg4Aum0nZnk3//+Zt3f/7OX+/+4O39j7TWKlSMZS5fnzF+NEJ22fHITjxuJ4kllbVmnZ1eh0a9RjIe+4KlNH50yM9v7/nt11+YTCacTqf0+30UJUCn06FSqX72ZSexWAx9JUcyESetJYmqERH/yFLaam/y5GTMi2enTKdTer0e3W6XkpGn3VqnVMzjsFtxOR2ifhvrTbqdNpm0RrvVYKfXxeH4DLDZqDMaDphdXTIYDKjX60QiEbJpjUG/S6NmEFJkgooHxeemWiowHOyRTmdobTTp77TxyK7/ALOZFEaxQFQNo+s6qVQKLZUil83QqJVorpZQQz6iYb9oSiqhsrm+SrfTYiWXJp2MovhkZPcHUElLJogvxQgGFBwOh5hBv99PSkuwVq9QKiyTiIVYioYIKF7UsEJai7Ox1iAUDGKxmMV8ym4nNpsVaTQ8wDCK9Pd22N/fF7bWbFLQcxzsdSkVV7BazHhlN9FIANntwOuRSac0kskEHo9HAH0ceun8/AWjwT43199zf/8Tx+Mxd3d3zK4umL05o9tew+91CYZe2SmYzBuUz+ssL2fw+XyEgwqyy/mhhrPZjNnVGyYnY55OJlTKZTbW13n2/DnfXV2wu72JlogKpkZBJ6VpqJEw1YpBoVAQmi8WVohFVRZNJqTb21vOzs4YDgfkdV3Iam5HR0fc3NxSLpdx2MzouSwe2c3CwgJul4PVqiHGay7ZWCxKoZAXedLtzQ37/T1GoyHlcoVGoyG6Pb98eXnJ48djetsdXp6fUatWhbafTk5oNlY5Ojpkd6fHdrfN61cvOTgYIJ1Ov+H4eMybi1dcX1+LpsxBJ0+eMC/H6ekphbyOlkwSDAYolwwGB/ssxWJk0mni8SUiIUXkFQ0DqWQUOTw85Pmzb0XyeDxmOBzxw+0NJ+NHYi4fPHggvjqX4Nw++vP4fF1cXCSbzQoJvgewfxZaaWfEhgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "In spite Of",
          "title": "In spite Of",
          "src": "/static/8840b1661354ddab3f610d9b40a16168/f79fa/In-spite-Of.png",
          "srcSet": ["/static/8840b1661354ddab3f610d9b40a16168/72799/In-spite-Of.png 320w", "/static/8840b1661354ddab3f610d9b40a16168/6af66/In-spite-Of.png 640w", "/static/8840b1661354ddab3f610d9b40a16168/f79fa/In-spite-Of.png 940w"],
          "sizes": "(max-width: 940px) 100vw, 940px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`Even more so, I have loved the history of African-Americans or what is often called “Black History.” When I decided to write a post in honor of Black History Month, I asked myself “why was I so drawn to African-American history?”`}</p>
    <p>{`After some thought, I realized it is because these special individuals made decisions “in-spite of” slavery, oppression, hatred, and fear.`}</p>
    <p>{`These types of stories have inspired me for my entire life, not because they are a part of my heritage or culture, but because I wanted to be a person that was courageous, a person that lived my life, my purpose, my values -- “in-spite of.” There are a few well-known historical figures like Martin Luther King, Jr. and Rosa Parks. However, there are many more, but less known, African-Americans that had the courage to act “in-spite of.”`}</p>
    <h3>{`Here are just a few:`}</h3>
    <h4>{`Charles Alston`}</h4>
    <p>{`During the 1930s, Charles founded the 306 group which convened in his studio space, providing support and apprenticeship to African-American artists. He also directed 35 artists for the Federal Arts Project in 1935 and 1936, creating murals for the Harlem Hospital (Biography.com).`}</p>
    <h4>{`Josephine Baker`}</h4>
    <p>{`African American performer, Josephine Baker smuggled military intelligence to French allies during World War II by pinning secrets inside her dress and hiding them in sheet music (Biography.com).`}</p>
    <h4>{`Bessie Coleman`}</h4>
    <p>{`In 1920, Bessie crossed the ocean with all her savings and financing from one of the first black millionaires to France with the goal of learning how to fly. She was awarded an international pilot's license in 1921. Over the next five years, Bessie performed air stunts and encouraged other African Americans to pursue flying, until she passed away in a flying accident (PBS).`}</p>
    <h4>{`Claudette Colvin`}</h4>
    <p>{`In 1955, nine months before Rosa Parks, a 15 year old Claudette refused to move to the back of the bus. She was one of four women that challenged the segregation laws in court (PBS).`}</p>
    <h4>{`Harriet E. Giles and Sophia B. Packard`}</h4>
    <p>{`In 1881, Sophia and Harriet founded what would become the first college for black women in the United States, Spelman College (Biography.com).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      